import { Language } from "./Context";


type TranslationKey = keyof Translations;

type Translations = {
  [key: string]: {
    [lang in Language]: string;
  };
};



const translations: Translations= {
    hero_heading: {
        DE: "Gatai - Ihr Partner für internationale Entwicklung",
        EN: "Gatai - Your Partner for International Development"
    },
    hero_description: {
        DE: "Hochwertige Fachkenntnisse für Stadtplanung und -umgestaltung, Handel und Personalentwicklung",
        EN: "Dedicated to provide high quality expertise for urban planning and transformation, trade and human resources development"
    },
    contact_us: {
        DE: "Kontakt",
        EN: "Get in touch"
    },
    nav_about: {
        DE: "Über",
        EN: "About"
    },
    nav_contact: {
      DE: "Kontakt",
      EN: "Contact"
    },
    about_header: {
      DE: "Unternehmensgeschichte",
      EN: "Company history and profile"
    },
    company_history: {
      DE: "Die Gatai Consulting and Trading GmbH wurde 2018 in Berlin als Unternehmen mit starkem Fokus auf internationale Märkte gegründet. Gatai möchte zu nachhaltigen Entwicklungs- und Innovationsherausforderungen beitragen, insbesondere in Europa, im Nahen Osten, in Asien und in anderen Bereichen. Gatai bietet Unterstützung für qualifizierte Fachkräfte, die eine Anstellung in Deutschland und anderen europäischen Ländern suchen. Wenn Sie nach Jobmöglichkeiten in Europa suchen, nehmen Sie bitte Kontakt mit uns auf.",
      EN: "Gatai Consulting and Trading GmbH was founded in 2018 in Berlin as a company with a strong focus on international markets. Gatai aims to contribute to sustainable development and innovation challenges especially in Europe, Middle East, Asia and in other areas. Gatai offers support for skilled and highly skilled professionals seeking employment in Germany and other European countries. If you are looking for job opportunities in Europe please get in touch with us."
    },
    founders_header: {
      DE: "Die Unternehmensgründer und ihre Erfahrungen",
      EN: "The company founders and their experience"
    },
    ghassan_description: {
      DE: "Elektroingenieur (Dipl.-Ing. Technische Universität Berlin) mit großer Erfahrung im Handel mit elektrischen Anlagen, Maschinen und Geräten im Nahen Osten. Er ist außerdem Eigentümer der Firma „Orient Electrical Equipment“ in Abu Dhabi.",
      EN: "Electrical Engineer (Dipl.-Ing. Technical University of Berlin) with large experience in trading of electrical equipment, machines and devices to the Middle East. He is also the owner of the company “Orient Electrical Equipment” in Abu Dhabi."
    },
    talal_description: {
      DE: "Ist Architekt und Stadtplaner (Dipl.-Ing. Technische Universität Berlin). Er leitet den Lehrstuhl für Architektur an der Universität Damaskus und hat Kongresshallen, Bürogebäude und Privathäuser gebaut. Er leitete ein mehrjähriges Projekt zur Sanierung des alten Stadtzentrums von Damaskus und war außerdem an städtischen Sanierungs- und Erneuerungsprojekten im Libanon und anderen Ländern beteiligt. Er ist Inhaber der Firma Arcod „Architectural Research Center of Old Damascus“ in Damaskus. Zu seinen Veröffentlichungen, die auf seinen Forschungen in Damaskus basieren, gehört das Buch „The Great Mosquee of Damascus – From Romam Temple to Islamic Monument“ (veröffentlicht in Englisch, Französisch und Deutsch).",
      EN: "is an architect and urban planner (Dipl.-Ing. Technical University of Berlin). He holds a chair in architecture at the Damascus University and has buildt convention halls, office buildings and private houses. He was the head of a multi-year project for the rehabilitation of the old City Centre of Damascus and has also worked in urban rehabilitation and renewal projects in Lebanon and other countries. He is the owner of the company Arcod “Architectural Research Centre of Old Damascus” in Damascus. Among his publications based on his research in Damascus is the book “The Great Mosquee of Damascus – From Romam Temple to Islamic Monument” (Published in Engl., French and German)."
    },
    julian_description: {
      DE: "Wirtschaftsingenieur (BSc., Technische Universität Berlin) und Fullstack Software Entwickler. Erfahrung in Webentwicklung, Webdesign, verteilten IT-Systemen und Backend-Entwicklung. Mehrjährige Berufserfahrung in funktionsübergreifenden Teams, agiler Entwicklung und Scrum.",
      EN: "Industrial Engineer (BSc., Technical University of Berlin) and working Fullstack Software Engineer. Experience in Web-Development, Web-Design, Distributed-IT-Systems and Back-End-Development. Several years of work experience in Cross Functional Teams, Agile development & Scrum."
    },
    ingo_description: {
      DE: "ist Sozialwissenschaftler (Dipl.-Pol. Freie Universität Berlin) und Erziehungswissenschaftler (PhD Technische Universität Berlin) mit Spezialisierung auf internationale Bildungs- und Trainingsprogramme. Er arbeitete als Berater und Managementtrainer für Industrie- und Entwicklungsorganisationen in Asien, dem Nahen Osten, Lateinamerika und Europa. Darüber hinaus war er an der Entwicklung des Alumni-Programms der Technischen Universität Berlin beteiligt und führte internationale Schulungen in Zusammenarbeit mit Partnern an Universitäten und in der Industrie durch. Er war verantwortlich für die Evaluierung von Ausbildungs- und Hochschulprogrammen sowie die Beratung in den Bereichen Personalentwicklung sowie wissenschaftliche Zusammenarbeit und Technologietransfer.",
      EN: "is a Social Scientist (Dipl.-Pol. Free University Berlin) and an Educational Scientist (PhD Technische Universität Berlin) with specialization in international education and training programs. He worked as a consultant and management trainer for industry and Development Organizations in Asia, Middle East, Latin America and Europe. Also he was involved in the development of the Alumni Program of Technische Universität Berlin and conducted international trainings in cooperation with partners at universities and in industry. He was assigned with the evaluation of training and higher education programs and consulting in Human Resources Development and in Scientific Co-operation and Technology Transfer."
    },
    contact_header: {
      DE: "Kontakt",
      EN: "Get in touch"
    },
    contact_sub_header: {
      DE: "Wir würden uns freien von Ihnen zu hören",
      EN: "We'd love to hear from you"
    },
    label_name: {
      DE: "Name",
      EN: "Full name"
    },
    label_mail: {
      DE: "E-Mail-Adresse",
      EN: "Email address"
    },
    label_phone: {
      DE: "Telefonnummer",
      EN: "Phone number"
    },
    label_message: {
      DE: "Nachricht",
      EN: "Message"
    },
    submit: {
      DE: "Absenden",
      EN: "Submit"
    },
    error_name: {
      DE: "Ein Name ist erforderlich.",
      EN: "A name is required."
    },
    error_mail: {
      DE: "Eine E-Mail ist erforderlich.",
      EN: "An email is required."
    },
    error_phone: {
      DE: "Eine Telefonnummer ist erforderlich.",
      EN: "A phone number is required."
    },
    error_message: {
      DE: "Eine Nachricht ist erforderlich.",
      EN: "A message is required."
    },
    form_success_header: {
      DE: "Wir sind dran!",
      EN: "We're on it!"
    },
    form_success_subheader: {
      DE: "Danke für die Nachricht.",
      EN: "Thanks for getting in touch."
    },


};
  
const getTranslation = (key: TranslationKey, lang: Language): string => {
    const translation = translations[key]?.[lang];
    if (translation) {
      return translation;
    }
    throw new Error(`Translation not found for key: ${key}`);  
};
  
export { translations, getTranslation };