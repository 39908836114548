import React, { createContext, useContext, useState } from 'react';

// Define the context type
interface ContextType {
  language: Language;
  setLanguage: React.Dispatch<React.SetStateAction<Language>>;
}

enum Language {
    DE = 'DE',
    EN = 'EN',
}

const AppContext = createContext<ContextType | undefined>(undefined);

// Custom hook to access the parent context
const GetAppContext = (): ContextType => {
    const context = useContext(AppContext);
  
    if (!context) {
      throw new Error('useParentContext must be used within a ParentComponent');
    }
  
    return context;
  };
// Create the context


export { GetAppContext, Language, AppContext };