import React from 'react';
import { getTranslation } from "../translations";
import { GetAppContext } from '../Context';
// import './App.css';

function Hero() {

    const { language } = GetAppContext();

    return (
        <header className="bg-dark py-5">
            <div className="container px-5">
                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-6">
                        <div className="text-center my-5">
                            {/* <h1 className="display-5 fw-bolder text-white mb-2">Gatai - Ihr Partner für internationale Entwicklung</h1> */}
                            <h1 className="display-5 fw-bolder text-white mb-2">{getTranslation("hero_heading", language)}</h1>
                            {/* <h1 className="display-5 fw-bolder text-white mb-2">{getTranslation("hero_heading", lang)}</h1> */}
                            <p className="lead text-white-50 mb-4">{getTranslation("hero_description", language)}</p>
                            <div className="d-grid gap-3 d-sm-flex justify-content-sm-center">
                                <a className="btn btn-primary btn-lg px-4 me-sm-3" href="#contact">{getTranslation("contact_us", language)}</a>
                                {/*<a className="btn btn-outline-light btn-lg px-4" href="#!">Learn More</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Hero; 