import React, { useContext } from 'react';
import { GetAppContext, Language } from '../Context';
import { getTranslation } from '../translations';
// import './App.css';

function Navigation() {

    const { language, setLanguage} = GetAppContext();

    function on_set_language(lang: Language) {
        let html = document.getElementsByTagName("html")[0];

        if (lang === Language.DE) {
            html.setAttribute("lang", "de");
        } else {
            html.setAttribute("lang", "en");
        }
        setLanguage(lang);
    }

    return (
        <nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
            <div className="container px-5">
                <a className="navbar-brand" href="#!">
                    <div className="logo-brand-wrapper">
                        <div className="saturn-icon"></div>
                        <div className="brand">Gatai</div>
                    </div>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item"><a className="nav-link active" aria-current="page" href="#">Home</a></li>
                        <li className="nav-item"><a className="nav-link" href="#about">{getTranslation("nav_about", language)}</a></li>
                        <li className="nav-item"><a className="nav-link" href="#contact">{getTranslation("nav_contact", language)}</a></li>
                        <li className="nav-item lang-nav-item"><a className={language === Language.DE ? "nav-link active" : "nav-link"} onClick={() => on_set_language(Language.DE)}>DE</a><span>/</span><a className={language === Language.EN ? "nav-link active" : "nav-link"} onClick={() => on_set_language(Language.EN)}>EN</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navigation; 