import React from "react";
// import './App.css';

function Footer() {
  return (
    <footer className="py-5 bg-dark">
      <div className="container px-5">
        <p className="m-0 text-center text-white">
          &copy; Gatai Consulting & Trading GmbH - Dr. Ingo Meyer Cranachstr.
          44, D-12157 Berlin, Germany
        </p>
      </div>
    </footer>
  );
}

export default Footer;
