import React from 'react';
import { GetAppContext } from '../Context';
import { getTranslation } from '../translations';
// import './App.css';

function About() {

    const { language } = GetAppContext();

    return (
        <section id="about" className="bg-light py-5">
            <div className="container px-5 my-5 px-5">
                <div className="text-center mb-3">
                    {/*<div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-envelope"></i></div>*/}
                    <h2 className="fw-bolder">{getTranslation("about_header", language)}</h2>
                    <p className="lead mb-0">{getTranslation("company_history", language)}</p>
                </div>
            </div>
        </section>
    );
}

export default About; 