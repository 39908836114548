import { mainModule } from "process";
import React, { useState } from "react";
import { GetAppContext } from "../Context";
import { getTranslation } from "../translations";

// import './App.css';

function Contact() {
  const url = "/contact-submission";

  const { language, setLanguage} = GetAppContext();

  const customHeight = {
    height: "10rem",
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [displayContact, setDisplayContact] = useState(true);
  const [displaySuccess, setDisplaySuccess] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (name.length === 0) {
      setNameError(true);
      // document.getElementById("name-error").style.display = "block";
      return;
    } else {
      setNameError(false);
      // document.getElementById("name-error").style.display = "none";
    }

    if (email.length === 0 && phone.length === 0) {
      if (email.length === 0) {
        setEmailError(true);
        // document.getElementById("name-error").style.display = "block";
        return;
      } else {
        setEmailError(false);
        // document.getElementById("name-error").style.display = "none";
      }

      if (phone.length === 0) {
        setPhoneError(true);
        setEmailError(false);
        // document.getElementById("name-error").style.display = "block";
        return;
      } else {
        setPhoneError(false);
        setEmailError(false);
        // document.getElementById("name-error").style.display = "none";
      }
    }

    if (message.length === 0) {
      setMessageError(true);
      setPhoneError(false);
      setEmailError(false);
      // document.getElementById("name-error").style.display = "block";
      return;
    } else {
      setMessageError(false);
      setPhoneError(false);
      setEmailError(false);
      setSubmitButtonDisabled(true);
      // document.getElementById("name-error").style.display = "none";
    }

    const jsonObject = {
        name: name,
        mail: email,
        phone_number: phone,
        message: message
      };
    
    fetch("contact-submission", {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(jsonObject)
    })
    .then(response => response.status)
    .then(status => {
    if (status === 200) {
        setDisplayContact(false);
        setDisplaySuccess(true);
    }
    })
    .catch(error => console.error(error))

    
  };

  return (
    <div>
    {displayContact ?
    <section id="contact" className="bg-light py-5">
      <div className="container px-5 my-5 px-5">
        <div className="text-center mb-5">
          <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
            <i className="bi bi-envelope"></i>
          </div>
          <h2 className="fw-bolder">{getTranslation("contact_header", language)}</h2>
          <p className="lead mb-0">{getTranslation("contact_sub_header", language)}</p>
        </div>
        <div className="row gx-5 justify-content-center">
          <div className="col-lg-6">
            <form id="contactForm" onSubmit={handleSubmit}>
              <div className="form-floating mb-3">
                <input
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  className="form-control"
                  id="name"
                  type="text"
                  placeholder="Namen eingeben..."
                  data-sb-validations="required"
                />
                <label>{getTranslation("label_name", language)}</label>
                {nameError && (
                  <div id="name-error" className="invalid-feedback">
                    {getTranslation("error_name", language)}
                  </div>
                )}
              </div>
              <div className="form-floating mb-3">
                <input
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  className="form-control"
                  id="mail"
                  type="email"
                  placeholder="name@example.com"
                  data-sb-validations="required"
                />
                <label>{getTranslation("label_mail", language)}</label>
                {emailError && (
                  <div id="mail-error" className="invalid-feedback">
                    {getTranslation("error_mail", language)}
                  </div>
                )}
              </div>
              <div className="form-floating mb-3">
                <input
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                  className="form-control"
                  id="phone"
                  type="tel"
                  placeholder="(123) 456-7890"
                  data-sb-validations="required"
                />
                <label>Telefonnummer</label>
                {phoneError && (
                  <div id="phone-error" className="invalid-feedback">
                    {getTranslation("label_phone", language)}
                  </div>
                )}
              </div>
              <div className="form-floating mb-3">
                <textarea
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  className="form-control"
                  id="message"
                  placeholder="Nachricht eingeben..."
                  style={customHeight}
                  data-sb-validations="required"
                ></textarea>
                <label>{getTranslation("label_message", language)}</label>
                {messageError && (
                  <div id="message-error" className="invalid-feedback">
                    {getTranslation("error_message", language)}
                  </div>
                )}
              </div>
              <div className="d-none" id="submitSuccessMessage">
                <div className="text-center mb-3">
                  <div className="fw-bolder">
                    Formular Absenden erfolgreich!
                  </div>
                  <br />
                </div>
              </div>
              <div className="d-none" id="submitErrorMessage">
                <div className="text-center text-danger mb-3">
                  Ihre Nachricht konnte nicht gesendet werden. Bitte überprüfen
                  Sie Ihre Internetverbindung.
                </div>
              </div>
              <div id="submit-div" className="d-grid">
                <input
                  className="btn btn-primary btn-lg"
                  id="submitButton"
                  type="submit"
                  value={getTranslation("submit", language)}
                  disabled={submitButtonDisabled}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section> : 
    <section id="form-success" className="bg-light py-5">
        <div className="container px-5 my-5 px-5 text-center">
            <div id="form-success">
                <div className="check-square-icon mb-3"></div>
                <h2>{getTranslation("form_success_header", language)}</h2>
                <div className="lead mb-0">{getTranslation("form_success_subheader", language)}</div>
            </div>
        </div>
    </section>}
    </div>
  );
}

export default Contact;
