import React from 'react';
import { GetAppContext } from '../Context';
import { getTranslation } from '../translations';
// import './App.css';

function DescriptionColumns() {

    const { language } = GetAppContext();

    return (
        <section className="py-5 border-bottom" id="features">
            <div className="text-center mb-5">
                {/*<div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-envelope"></i></div>*/}
                <h3 className="fw-bolder mt-5 mb-0">{getTranslation("founders_header", language)}</h3>
            </div>
            <div className="container px-5 my-5">
                <div className="row gx-5">
                    <div className="col-lg-3 mb-5 mb-lg-0">
                        {/*<div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-collection"></i></div>*/}
                        <h2 className="h4 fw-bolder">Ghassan Abbara</h2>
                        <p>
                        {getTranslation("ghassan_description", language)}</p>
                        {/*<a className="text-decoration-none" href="#!">
                            Call to action
                            <i className="bi bi-arrow-right"></i>
                        </a>*/}
                    </div>
                    <div className="col-lg-3 mb-5 mb-lg-0">
                        {/*<div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-building"></i></div>*/}
                        <h2 className="h4 fw-bolder">Talal Akili, PhD</h2>
                        <p>{getTranslation("talal_description", language)}</p>
                        {/*<a className="text-decoration-none" href="#!">
                            Call to action
                            <i className="bi bi-arrow-right"></i>
                        </a>*/}
                    </div>
                    <div className="col-lg-3 mb-5 mb-lg-0">
                        {/*<div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-toggles2"></i></div>*/}
                        <h2 className="h4 fw-bolder">Julian Martin</h2>
                        <p>{getTranslation("julian_description", language)}</p>
                        {/*<a className="text-decoration-none" href="#!">
                            Call to actio
                            <i className="bi bi-arrow-right"></i>
                        </a>*/}
                    </div>
                    <div className="col-lg-3">
                        {/*<div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-toggles2"></i></div>*/}
                        <h2 className="h4 fw-bolder">Ingo Meyer, PhD</h2>
                        <p>{getTranslation("ingo_description", language)}</p>
                        {/*<a className="text-decoration-none" href="#!">
                            Call to action
                            <i className="bi bi-arrow-right"></i>
                        </a>*/}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DescriptionColumns; 