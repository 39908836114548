import React from "react";
import "./components/MyComponent";
import "./components/Hero";
import "./components/About";
import "./components/DescriptionColumns";
import "./components/Pricing";
import "./components/Footer";
import Navigation from "./components/Navigation";
import Hero from "./components/Hero";
import "./css/styles.css";
import "./css/custom_style.css";
import About from "./components/About";
import DescriptionColumns from "./components/DescriptionColumns";
import Pricing from "./components/Pricing";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import {AppContext, Language} from "./Context";


function App() {
  const [language, setLanguage] = React.useState(Language.DE);

  return (
    <AppContext.Provider value = {{language, setLanguage}}>
      <div className="App">
        <header className="App-header">
          <Navigation />
          <Hero />
        </header>
        <About />
        <DescriptionColumns />
        {/* <Pricing />
        <Testimonials /> */}
        <Contact />
        <Footer />
        <script src="./scripts.js"></script>
      </div>
    </AppContext.Provider>
  );
}

export default App;
